import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["newApplicantTemplate", "newApplicantTarget"];

  static values = {
    wrapperSelector: {
      type: String,
      default: ".templated-field-wrapper",
    },
  };

  connect() {
    this.applicantCount = this.element.querySelectorAll(".templated-field-wrapper").length;
  }

  add(_e) {
    // Count the exisiting applicants
    const existingApplicantsCount = this.element.querySelectorAll(".templated-field-wrapper").length;
    // Replace inner html __NEW_RECORD__ place holder with correct index
    let content = this.newApplicantTemplateTarget.innerHTML.replace(/__NEW_RECORD__/g, this.applicantCount);
    content = content.replace(/__NEW_RECORD_SHOW_INDEX__/g, existingApplicantsCount);
    this.newApplicantTargetTarget.insertAdjacentHTML("beforebegin", content);
    this.applicantCount += 1;
  }

  // NOTE: Indexes are valid because one applicant is always required and cannot be removed (+1 offset)
  remove(e) {
    // Spans that hold index of applicant shown in view to customer (ie applicant 1, applicant 2)
    const applicantShowIndexSpans = this.element.querySelectorAll("[data-applicant-show-index]");
    // Parent container of removed applicant
    const wrapper = e.target.closest(this.wrapperSelectorValue);
    const wrapperInput = wrapper.querySelector("input"); // Get any input from reference, we just need the name for index
    const indexRegex = /\[(\d+)\]/;
    // Removed reference index parsed to integer
    const deletedIndex = parseInt(indexRegex.exec(wrapperInput.name)[1]);

    // Id input of deleted index
    const deletedIdInput = wrapper.parentElement.querySelector(
      `input[name='order[applicant_reports_attributes][${deletedIndex}][id]']`,
    );

    // If applicant was persisted and the value is present
    if (deletedIdInput && deletedIdInput.value) {
      // Create a destroy input element in the parent element to mark this record for deletion
      const name = `order[applicant_reports_attributes][${deletedIndex}][_destroy]`;
      const id = `order_applicant_reports_attributes_${deletedIndex}__destroy`;
      const content = `
        <input id=${id} name=${name} type='hidden' value=1 />
      `;

      // Insert content only if hidden input doesn't exist
      if (wrapper.parentElement.querySelector(`#${id}`) === null) {
        wrapper.parentElement.insertAdjacentHTML("afterbegin", content);
      }
    }

    // Then remove the applicant entirely and update the shown indexes for any applicant with a greater index
    wrapper.remove();
    applicantShowIndexSpans.forEach((elem) => {
      const showIndex = parseInt(elem.innerHTML);
      if (deletedIndex < showIndex) {
        elem.innerHTML = showIndex - 1;
      }
    });
  }
}
