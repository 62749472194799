import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    const expressSubmitBtn = this.element.querySelector("#new_order_express_submit");
    const manualSubmitBtn = this.element.querySelector("#new_order_manual_submit");
    const submissionType = this.element.querySelector("#order_express_submission");

    // If manual submit button was clicked set express_submission to false
    manualSubmitBtn?.addEventListener("click", (_e) => {
      submissionType.value = false;
    });

    // If express submit button was clicked set express_submission to true
    expressSubmitBtn?.addEventListener("click", (_e) => {
      submissionType.value = true;
    });

    // Add form validation
    this.element.addEventListener("submit", this.validateForm.bind(this));
  }

  validateForm(event) {
    // Check for new Dropzone uploads
    const hasNewFiles = document.querySelector('.dz-preview.dz-success') !== null;

    // Check for existing persisted files
    const hasPersistedFiles = document.querySelector('[data-persisted-files]')?.getAttribute('data-persisted-files') === 'true';

    // Check for files waiting to be uploaded
    const hasAttachedFiles = document.querySelector('[data-dropzone-target="input"]')?.files?.length > 0;

    if (!hasNewFiles && !hasPersistedFiles && !hasAttachedFiles) {
      event.preventDefault();
      alert('Please upload at least one application file');
      return false;
    }
  }
}
