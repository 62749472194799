import { Controller } from "@hotwired/stimulus";

// Submit button controller in cancel report modal.
export default class extends Controller {
  connect() {
    const textArea = document.getElementById("applicant_report_cancelled_reason");

    textArea.addEventListener("input", (e) => {
      this.element.disabled = e.target.value ? false : true;
    });
  }
}
