import { Controller } from "@hotwired/stimulus";
import { showBlock, hide } from '../../../utils';
import { setupTooltips } from "../../../bootstrap";

// Reference Residence Summary Form controller
export default class extends Controller {

  connect() {
    this.wouldRentAgain = this.element.querySelector('select[name="reference[would_rent_again]"]');
    this.numberOfAnimals = this.element.querySelector('#reference_number_of_animals');
    this.animalsNoteDiv = this.element.querySelector('#animals-note-div');
    this.animalsNote = this.element.querySelector('#reference_animals_note');
    this.wouldNotRentAgainNoteDiv = this.element.querySelector('#would-not-rent-again-note-div');
    this.wouldNotRentAgainNote = this.element.querySelector('#reference_would_not_rent_again_note');
    this.pestIssues = this.element.querySelector('#reference_pest_issues');
    this.pestIssuesNoteDiv = this.element.querySelector('#pest-issues-note-div');
    this.pestIssuesNote = this.element.querySelector('#reference_pest_issues_note');
    this.leaseViolations = this.element.querySelector('#reference_lease_violations');
    this.leaseViolationsNoteDiv = this.element.querySelector('#lease-violations-note-div');
    this.leaseViolationsNote = this.element.querySelector('#reference_lease_violations_note');

    this.wouldRentAgain?.addEventListener("change", this.wouldRentAgainEventListener.bind(this));
    this.numberOfAnimals?.addEventListener("input", this.numberOfAnimalsEventListener.bind(this));
    this.pestIssues?.addEventListener("change", this.pestIssuesEventListener.bind(this));
    this.leaseViolations?.addEventListener("change", this.leaseViolationsEventListener.bind(this));

    this.syncFormWithNumberOfAnimals();
    this.syncFormWithWouldRentAgain();
    this.syncFormWithPestIssues();
    this.syncFormWithLeaseViolations();
    setupTooltips();
  }

  numberOfAnimalsEventListener(e) {
    this.syncFormWithNumberOfAnimals();
  }

  wouldRentAgainEventListener(e) {
    this.syncFormWithWouldRentAgain();
  }

  pestIssuesEventListener(e) {
    this.syncFormWithPestIssues();
  }

  leaseViolationsEventListener(e) {
    this.syncFormWithLeaseViolations();
  }

  syncFormWithNumberOfAnimals() {
    if (!this.numberOfAnimals) return

    let value = this.numberOfAnimals.value;
    if (!this.animalsNote) return

    if (value > 0) {
      showBlock(this.animalsNoteDiv);
      this.animalsNote.required = true;
    } else {
      hide(this.animalsNoteDiv);
      this.animalsNote.required = false;
    }
  }

  syncFormWithWouldRentAgain() {
    this.toggleNoteVisibility({
      field: this.wouldRentAgain,
      noteField: this.wouldNotRentAgainNote,
      noteDiv: this.wouldNotRentAgainNoteDiv,
      showOnValue: 'false'
    });
  }

  syncFormWithPestIssues() {
    this.toggleNoteVisibility({
      field: this.pestIssues,
      noteField: this.pestIssuesNote,
      noteDiv: this.pestIssuesNoteDiv,
      showOnValue: 'true'
    });
  }

  syncFormWithLeaseViolations() {
    this.toggleNoteVisibility({
      field: this.leaseViolations,
      noteField: this.leaseViolationsNote,
      noteDiv: this.leaseViolationsNoteDiv,
      showOnValue: 'true'
    });
  }

  toggleNoteVisibility({ field, noteField, noteDiv, showOnValue }) {
    if (!field || !noteField) return;

    const shouldShow = field.value === showOnValue;
    if (shouldShow) {
      showBlock(noteDiv);
      noteField.required = true;
    } else {
      hide(noteDiv);
      noteField.required = false;
    }
  }
}
