import { Controller } from "@hotwired/stimulus";
import {
  templatedEvents,
  toggleCall,
  toggleCallResultSelectVisibility,
  setupOutcomeDropdownChoiceManagement,
  filterTemplateDropdownList,
  toggleOutreachEventMessageBodyInput,
} from "../../../../frontend/javascript/utils/outreach_events";

export default class extends Controller {
  connect() {
    this.canvas = document.getElementById("outreach_canvas");
    this.inbound_canvas = document.getElementById("inbound_messages_outreach_canvas");
    this.call_canvas = document.getElementById("call_script_outreach_canvas");
    this.message_canvas = document.getElementById("templated_message_outreach_canvas");
    this.canvas.appendChild(this.inbound_canvas);
    this.canvas.appendChild(this.call_canvas);
    this.canvas.appendChild(this.message_canvas);

    this.callFormContainer = this.element.querySelector("#call_form_container");
    this.contactMethodDropdown = this.element.querySelector("#outreach_event_applicant_report_contact_method");
    this.directionDropdown = this.element.querySelector("#outreach_event_applicant_report_direction");
    this.templateDropdown = this.element.querySelector("#outreach_event_applicant_report_template_used");
    this.scriptDropdown = this.element.querySelector("#script-dropdown");
    this.outcomeOptionsDropdown = this.element.querySelector("#outreach_event_applicant_report_outcome");
    this.referenceDropdown = this.element.querySelector("#reference-dropdown");
    this.contactResultDropdown = this.element.querySelector("#outreach_event_applicant_report_contact_result");
    this.contactResultDropdownWrapper = this.element.querySelector(
      "#outreach_event_applicant_report_contact_result_select_wrapper",
    );
    this.applicantEmailInput = this.element.querySelector(
      "#outreach_event_applicant_report_outreach_eventable_attributes_email",
    );
    this.phoneInput = this.element.querySelector(
      "#outreach_event_applicant_report_outreach_eventable_attributes_phone",
    );
    this.smsEnabledInput = this.element.querySelector(
      "#outreach_event_applicant_report_outreach_eventable_attributes_phone_sms_enabled",
    );
    this.sendContactEmailCheckbox = this.element.querySelector("#send_applicant_initial_outreach");

    toggleCall(this.call_canvas, this.callFormContainer, this.contactMethodDropdown, this.scriptDropdown);
    toggleCallResultSelectVisibility(
      this.contactResultDropdown,
      this.contactResultDropdownWrapper,
      this.contactMethodDropdown,
    );
    this.toggleSendApplicantInitialOutreach();
    this.toggleInboundMessages();
    setupOutcomeDropdownChoiceManagement(
      this.outcomeOptionsDropdown,
      this.contactMethodDropdown,
      this.directionDropdown,
      this.contactResultDropdown,
    );
    this.setupTemplatedEventsExperience();
  }

  toggleInboundMessages() {
    this.inbound_canvas.hidden = this.directionDropdown.value !== "INBOUND";
  }

  setupTemplatedEventsExperience() {
    const getRenderedMessageIds = () => {
      let element = document.getElementById("rendered-message-ids");
      let renderedMessageIds = JSON.parse(element.getAttribute("data-rendered-message-ids"));
      return renderedMessageIds;
    };

    const getTemplateMetadata = () => {
      let element = document.getElementById("message-template-metadata");
      let templateMetadata = JSON.parse(element.getAttribute("data-message-template-metadata"));
      return templateMetadata;
    };

    const toggleReferencePickerBasedOnTemplate = () => {
      let templateMetadata = getTemplateMetadata();
      if (
        templateMetadata[this.templateDropdown.value] &&
        templateMetadata[this.templateDropdown.value].requires_reference
      ) {
        document.getElementById("reference-picker").hidden = false;
      } else {
        document.getElementById("reference-picker").hidden = true;
      }
    };

    const toggleTemplatedEventsExperience = () => {
      if (templatedEvents().includes(this.contactMethodDropdown.value) && this.directionDropdown.value === "OUTBOUND") {
        this.message_canvas.hidden = false;
        document.getElementById("templated-events-experience").hidden = false;
        this.templateDropdown.required = true;
        this.templateDropdown.value = "applicant_outreach";
        this.templateDropdown.dispatchEvent(new Event("change"));
      } else {
        this.message_canvas.hidden = true;
        document.getElementById("templated-events-experience").hidden = true;
        this.templateDropdown.required = false;
        this.templateDropdown.value = null;
      }
    };

    const showCorrectMessageTemplate = () => {
      let renderedMessageIds = getRenderedMessageIds();
      let idToShow = this.contactMethodDropdown.value?.toLowerCase() + "_" + this.templateDropdown.value + "_";
      if (this.referenceDropdown) {
        idToShow = idToShow + this.referenceDropdown.value;
      }
      for (let i = 0; i < renderedMessageIds.length; i++) {
        if (renderedMessageIds[i] === idToShow) {
          document.getElementById(renderedMessageIds[i]).hidden = false;
        } else {
          document.getElementById(renderedMessageIds[i]).hidden = true;
        }
      }
    };

    const setupMessageBodyInput = () => {
      let messageBodyInputDiv = document.getElementById("message-body-input-div");
      let messageBodyInput = document.getElementById("message-body-input");
      let templateMetadata = getTemplateMetadata();
      toggleOutreachEventMessageBodyInput(
        messageBodyInputDiv,
        messageBodyInput,
        templateMetadata,
        this.templateDropdown,
      );
    };

    const setupTemplateDropdownList = () => {
      let templateMetdata = getTemplateMetadata();
      filterTemplateDropdownList(templateMetdata, this.contactMethodDropdown, this.templateDropdown);
    };

    const selectCorrectOutcomeBasedOnTemplateUsed = () => {
      let templateMetdata = getTemplateMetadata();
      if (
        templateMetdata[this.templateDropdown.value] &&
        templateMetdata[this.templateDropdown.value].expected_outcome
      ) {
        this.outcomeOptionsDropdown.value = templateMetdata[this.templateDropdown.value].expected_outcome;
      }
    };

    this.templateDropdown.addEventListener("change", (e) => {
      showCorrectMessageTemplate();
      toggleReferencePickerBasedOnTemplate();
      selectCorrectOutcomeBasedOnTemplateUsed();
      setupMessageBodyInput();
      setupTemplateDropdownList();
    });
    if (this.referenceDropdown) {
      this.referenceDropdown.addEventListener("change", (e) => {
        showCorrectMessageTemplate();
      });
    }
    this.contactMethodDropdown.addEventListener("change", (e) => {
      showCorrectMessageTemplate();
      toggleTemplatedEventsExperience();
      setupMessageBodyInput();
      setupTemplateDropdownList();
    });
    this.directionDropdown.addEventListener("change", (e) => {
      showCorrectMessageTemplate();
      toggleTemplatedEventsExperience();
      this.toggleInboundMessages();
    });
    showCorrectMessageTemplate();
    toggleReferencePickerBasedOnTemplate();
    toggleTemplatedEventsExperience();
    selectCorrectOutcomeBasedOnTemplateUsed();
    setupMessageBodyInput();
    setupTemplateDropdownList();
  }

  toggleSendApplicantInitialOutreach() {
    const toggleSendInitialOutreach = () => {
      let email = this.applicantEmailInput.value;
      let phoneNumber = this.phoneInput.value;
      let smsEnabled = this.smsEnabledInput.checked;
      if (this.sendContactEmailCheckbox) {
        this.sendContactEmailCheckbox.checked = false;
        this.sendContactEmailCheckbox.disabled = !(email || (phoneNumber && smsEnabled));
      }
    };
    this.applicantEmailInput?.addEventListener("input", (e) => {
      toggleSendInitialOutreach();
    });
    this.phoneInput?.addEventListener("change", (e) => {
      toggleSendInitialOutreach();
    });
    this.smsEnabledInput?.addEventListener("change", (e) => {
      toggleSendInitialOutreach();
    });
    this.contactMethodDropdown?.addEventListener("change", (_e) => {
      toggleSendInitialOutreach();
    });
  }
}
