import { Controller } from '@hotwired/stimulus';
import { loadStripe } from '@stripe/stripe-js';

export default class extends Controller {
  async connect() {
    const stripePublishableKeyDataElement = document.getElementById("stripe-publishable-key");
    const stripePublishableKey = stripePublishableKeyDataElement.getAttribute("data-stripe-publishable-key");
    const stripe = await loadStripe(stripePublishableKey);
    const stripeClientSecretDataElement = document.getElementById("stripe-client-secret");
    const stripeClientSecret = stripeClientSecretDataElement.getAttribute("data-stripe-checkout-client-id");
    this.checkout = await stripe.initEmbeddedCheckout({
      clientSecret: stripeClientSecret
    });
    this.checkout.mount('#checkout');
  }

  async disconnect() {
    this.checkout?.unmount();
    this.checkout?.destroy();
  }
}
